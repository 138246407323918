import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
   AiFillGithub,
   AiOutlineTwitter,
   AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
   return (
      <Container fluid className="footer">
         <Row>
            <Col md="4" className="footer-copywright">
               <h3>Developed by Anish Singh</h3>
            </Col>
            <Col md="4" className="footer-links">
               <Link to="/privacy">Privacy Policy</Link>
               <BsDot />

               <Link to="/terms">Terms of Use</Link>
               <BsDot />

               <Link to="/refund">Refund Policy</Link>
            </Col>
            <Col md="4" className="footer-body">
               <ul className="footer-icons">
                  <li className="social-icons">
                     <a
                        href="https://github.com/AnishSingh5665"
                        style={{ color: "white" }}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <AiFillGithub />
                     </a>
                  </li>
                  <li className="social-icons">
                     <a
                        href="https://twitter.com/Anish_Singh_32"
                        style={{ color: "white" }}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <AiOutlineTwitter />
                     </a>
                  </li>
                  <li className="social-icons">
                     <a
                        href="https://www.linkedin.com/in/anishsinghssaa/"
                        style={{ color: "white" }}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <FaLinkedinIn />
                     </a>
                  </li>
                  <li className="social-icons">
                     <a
                        href="https://www.instagram.com/wht_hats_ani56"
                        style={{ color: "white" }}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        <AiFillInstagram />
                     </a>
                  </li>
               </ul>
            </Col>
         </Row>
      </Container>
   );
}

export default Footer;
