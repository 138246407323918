import { Container } from "react-bootstrap";
import Particle from "../Particle";

export default function Privacy() {
   return (
      <Container fluid className="project-section coffee-section">
         <Particle />
         <Container className="coffee">
            <h1 className="project-heading">Terms of Use</h1>
            <div className="cover-text">
               <p>
                  By accessing and using Anish&#39;s Portfolio Website (the
                  &quot;Website&quot;), you agree to comply with and be bound by
                  these Terms of Use. If you do not agree to these terms, please
                  do not use the Website.
               </p>
               <h2 id="use-of-the-website">Use of the Website</h2>
               <ul>
                  <li>
                     <strong>Content</strong>: All content provided on the
                     Website is for informational purposes only. While we strive
                     to ensure the accuracy of the information, we make no
                     guarantees regarding the completeness, reliability, or
                     accuracy of any content.
                  </li>
                  <li>
                     <strong>Modification of Information</strong>: You cannot
                     modify or change information entered in the contact form.
                  </li>
                  <li>
                     <strong>Prohibited Activities</strong>: You agree not to
                     use the Website for any unlawful purposes or to engage in
                     any activity that could harm the Website or its users.
                  </li>
               </ul>
               <h2 id="intellectual-property">Intellectual Property</h2>
               <p>
                  All content on the Website, including but not limited to text,
                  graphics, logos, images, and software, is the property of
                  Anish and is protected by intellectual property laws. You may
                  not reproduce, distribute, or create derivative works from any
                  content without explicit permission.
               </p>
               <h2 id="privacy-policy">Privacy Policy</h2>
               <p>
                  Your use of the Website is also governed by our Privacy
                  Policy. Please review it to understand our practices regarding
                  the collection and use of your personal information.
               </p>
               <h2 id="limitation-of-liability">Limitation of Liability</h2>
               <p>
                  In no event shall Anish be liable for any direct, indirect,
                  incidental, special, or consequential damages arising out of
                  or in connection with your use of the Website. This includes,
                  but is not limited to, damages for loss of profits, data, or
                  other intangible losses.
               </p>
               <h2 id="changes-to-the-terms">Changes to the Terms</h2>
               <p>
                  We reserve the right to modify these Terms of Use at any time.
                  Any changes will be posted on this page with an updated
                  effective date. Your continued use of the Website after any
                  such changes constitutes your acceptance of the new terms.
               </p>
               <h2 id="contact-information">Contact Information</h2>
               <p>
                  If you have any questions about these Terms of Use, please
                  contact us at{" "}
                  <a href="mailto:29991csaiml@gmail.com">
                     29991csaiml@gmail.com
                  </a>
                  .
               </p>
               <p>
                  <em>This portfolio is owned by Anish Singh</em>
               </p>
            </div>
         </Container>
      </Container>
   );
}
