import Particle from "../Particle";
import { Container } from "react-bootstrap";
import ContactForm from "./ContactForm";
export default function Contact() {
   return (
      <Container fluid className="project-section coffee-section">
         <Particle />
         <Container className="coffee">
            <h1 className="project-heading">
               Get in <strong className="purple">Touch</strong>
            </h1>
            <p style={{ color: "white" }}>
               Feel free to reach out if you have any questions or just want to
               say hi.
            </p>
            <div className="coffee-container">
               <ContactForm />
            </div>
         </Container>
      </Container>
   );
}
