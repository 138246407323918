import { Container } from "react-bootstrap";
import Particle from "../Particle";

export default function Privacy() {
   return (
      <Container fluid className="project-section coffee-section">
         <Particle />
         <Container className="coffee">
            <h1 className="project-heading">Privacy Policy</h1>
            <div className="cover-text">
               <p>
                  Welcome to Anish&#39;s Portfolio Website. Your privacy is
                  important to us. This privacy policy explains how we collect,
                  use, and protect your personal information when you visit our
                  website.
               </p>
               <h2 id="information-we-collect">Information We Collect</h2>
               <ul>
                  <li>
                     <strong>Personal Information:</strong> When you contact us
                     or use our services, we may collect personal information
                     such as your name, email address.
                  </li>
                  <li>
                     <strong>Payment Information:</strong> If you make a payment
                     through Razorpay, we will collect payment information to
                     process your transaction.
                  </li>
               </ul>
               <h2 id="how-we-use-your-information">
                  How We Use Your Information
               </h2>
               <ul>
                  <li>
                     <strong>To Provide Services:</strong> We use your personal
                     information to respond to your inquiries, provide services,
                     and improve our website.
                  </li>
                  <li>
                     <strong>Payment Processing:</strong> We use Razorpay to
                     process payments. Your payment information is used only for
                     transaction verification and processing purposes.
                  </li>
               </ul>
               <h2 id="data-security">Data Security</h2>
               <p>
                  We implement appropriate security measures to protect your
                  personal information from unauthorized access, alteration,
                  disclosure, or destruction.{" "}
               </p>
               <h2 id="sharing-your-information">Sharing Your Information</h2>
               <p>
                  We do not sell, trade, or otherwise transfer your personal
                  information to outside parties except to trusted third parties
                  who assist us in operating our website, conducting our
                  business, or servicing you, as long as those parties agree to
                  keep this information confidential.
               </p>
               <h2 id="your-rights">Your Rights</h2>
               <p>
                  You cannot modify or change information entered in the contact
                  form.
               </p>
               <h2 id="changes-to-this-privacy-policy">
                  Changes to This Privacy Policy
               </h2>
               <p>
                  We may update this privacy policy from time to time. Any
                  changes will be posted on this page with an updated effective
                  date.
               </p>
               <h2 id="contact-us">Contact Us</h2>
               <p>
                  If you have any questions about this privacy policy, please
                  <a href="mailto:29991csaiml@gmail.com"> contact us</a>.
               </p>

               <p>
                  <em>This portfolio is owned by Anish Singh</em>
               </p>
            </div>
         </Container>
      </Container>
   );
}
