import { Container } from "react-bootstrap";
import Particle from "../Particle";
import CoffeeForm from "./Form";
import Supporters from "./Supporters";

export default function BuyMeCoffee() {
   return (
      <Container fluid className="project-section coffee-section">
         <Particle />
         <Container className="coffee">
            <h1 className="project-heading">
               Buy me <strong className="purple">Coffee</strong>
            </h1>
            <p style={{ color: "white" }}>
               If you like my work and want to support me, you can buy me a
               coffee.
            </p>

            <div className="coffee-container">
               <CoffeeForm />
               <div className="coffee-border"></div>
               <Supporters />
            </div>
         </Container>
      </Container>
   );
}
