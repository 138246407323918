import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function createMailtoLink(repository) {
   const email = "29991csaiml@gmail.com";
   const subject = "Request for Access to GitHub Repository";
   const body = `Hi Anish,

I hope you're doing well. I am reaching out to request access to your GitHub repository, ${repository}. 

I believe it will be beneficial for my work, and I would like to collaborate or review the content as needed.

Here are my GitHub details:

Username: 
Email associated with GitHub: 

Please let me know if there's any further information required. I'd be happy to comply with any permissions or collaboration guidelines you have.

Thank you for considering my request!`;
   // Encode the subject and body to handle special characters
   const encodedSubject = encodeURIComponent(subject);
   const encodedBody = encodeURIComponent(body);

   // Create the mailto link
   const mailtoLink = `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`;

   return mailtoLink;
}

function ProjectCards(props) {
   return (
      <Card className="project-card-view">
         <Card.Img variant="top" src={props.imgPath} alt="card-img" />
         <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text style={{ textAlign: "justify" }}>
               {props.description}
            </Card.Text>
            <Button
               variant="primary"
               href={createMailtoLink(props.ghLink)}
               target="_blank"
            >
               <BsGithub /> &nbsp;
               {props.isBlog ? "Blog" : "GitHub"}
            </Button>
            {"\n"}
            {"\n"}

            {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

            {!props.isBlog && props.demoLink && (
               <Button
                  variant="primary"
                  href={props.demoLink}
                  target="_blank"
                  style={{ marginLeft: "10px" }}
               >
                  <CgWebsite /> &nbsp;
                  {"Demo"}
               </Button>
            )}
         </Card.Body>
      </Card>
   );
}
export default ProjectCards;
