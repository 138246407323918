import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode, 
  SiPostman,           
  SiStreamlit,         
  SiAnaconda,          
  SiGooglecolab,       
  SiDocker,            
  SiGithub,            
  SiJupyter,                        
  SiVercel,  
  SiHeroku,                
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiStreamlit />
        <div className="text">Streamlit</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnaconda />
        <div className="text">Anaconda</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglecolab />
        <div className="text">Google Colab</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <div className="text">Docker</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub />
        <div className="text">Github</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJupyter />
        <div className="text">Jupyter</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <div className="text">Visual Studio Code</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <div className="text">Postman</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <div className="text">Vercel</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
        <div className="text">Heroku</div>
      </Col>
    </Row>
  );
}

export default Toolstack;
