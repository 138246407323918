import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logonew.png";
import { CiCoffeeCup } from "react-icons/ci";
import { Link } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import {
   AiOutlineHome,
   AiOutlineFundProjectionScreen,
   AiOutlineUser,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";

function NavBar() {
   const [expand, updateExpanded] = useState(false);
   const [navColour, updateNavbar] = useState(false);

   function scrollHandler() {
      if (window.scrollY >= 20) {
         updateNavbar(true);
      } else {
         updateNavbar(false);
      }
   }

   window.addEventListener("scroll", scrollHandler);

   return (
      <Navbar
         expanded={expand}
         fixed="top"
         expand="md"
         className={navColour ? "sticky" : "navbar"}
      >
         <Container>
            <Navbar.Brand href="/" className="d-flex">
               <img src={logo} className="img-fluid logo" alt="brand" />
            </Navbar.Brand>
            <Navbar.Toggle
               aria-controls="responsive-navbar-nav"
               onClick={() => {
                  updateExpanded(expand ? false : "expanded");
               }}
            >
               <span></span>
               <span></span>
               <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
               <Nav className="ms-auto" defaultActiveKey="#home">
                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/"
                        onClick={() => updateExpanded(false)}
                     >
                        <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                     </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/about"
                        onClick={() => updateExpanded(false)}
                     >
                        <AiOutlineUser style={{ marginBottom: "2px" }} /> About
                     </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/project"
                        onClick={() => updateExpanded(false)}
                     >
                        <AiOutlineFundProjectionScreen
                           style={{ marginBottom: "2px" }}
                        />{" "}
                        Projects
                     </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/resume"
                        onClick={() => updateExpanded(false)}
                     >
                        <CgFileDocument style={{ marginBottom: "2px" }} />{" "}
                        Resume
                     </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/contact"
                        onClick={() => updateExpanded(false)}
                     >
                        <ImBlog style={{ marginBottom: "2px" }} /> Contact Me
                     </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                     <Nav.Link
                        as={Link}
                        to="/buy-me-coffee"
                        onClick={() => updateExpanded(false)}
                     >
                        <CiCoffeeCup style={{ marginBottom: "2px" }} /> Buy me
                        Coffee
                     </Nav.Link>
                  </Nav.Item>
               </Nav>
            </Navbar.Collapse>
         </Container>
      </Navbar>
   );
}

export default NavBar;
