import { Container } from "react-bootstrap";
import Particle from "../Particle";

export default function Refund() {
   return (
      <Container fluid className="project-section coffee-section">
         <Particle />
         <Container className="coffee">
            <h1 className="project-heading">Refund Policy</h1>
            <div className="cover-text">
               <p>
                  All payments made on Anish&#39;s Portfolio Website, including
                  donations and &quot;Buy Me a Coffee&quot; contributions, are
                  final. We do not offer refunds for any transactions.
               </p>
               <h2 id="contact-information">Contact Information</h2>
               <p>
                  If you have any questions or concerns about this Refund
                  Policy, please{" "}
                  <a href="mailto:29991csaiml@gmail.com"> contact us</a>.
               </p>
               <p>
                  <em>This portfolio is owned by Anish Singh</em>
               </p>
            </div>
         </Container>
      </Container>
   );
}
