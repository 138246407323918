import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supabase"; // Ensure this path is correct

export default function Supporters() {
   const [supporters, setSupporters] = useState([]);

   useEffect(() => {
      const fetchSupporters = async () => {
         const { data, error } = await supabase
            .from("coffee_messages")
            .select("id, full_name, number_of_coffees")
            .eq("paid", true)
            .order("creation_time", { ascending: false })
            .limit(10);

         if (error) {
            console.error("Error fetching supporters:", error);
         } else {
            setSupporters(data);
         }
      };

      fetchSupporters();
   }, []);

   return (
      <div className="coffee-supporters">
         <h5 className="mb-4">Recent Supporters</h5>
         <div className="supporters-list">
            {supporters.map((supporter) => (
               <Supporter
                  key={supporter.id}
                  name={supporter.full_name}
                  message={`bought ${supporter.number_of_coffees} cup(s) of coffee`}
               />
            ))}
         </div>
      </div>
   );
}

function Supporter({ name, message }) {
   return (
      <div className="supporter">
         <span style={{ color: "white", marginRight: "1ch" }}>{name} </span>
         <span>{message}</span>
      </div>
   );
}
