import React from "react";
import { Col, Row } from "react-bootstrap";
import {
   DiPython,
   DiJavascript1,
   DiMongodb,
   DiGit,
   DiHtml5,
   DiCss3,
} from "react-icons/di";
import {
   SiOpencv,
   SiTensorflow,
   SiKeras,
   SiPytorch,
   SiScikitlearn,
   SiPandas,
   SiNumpy,
   SiJupyter,
   SiDocker,
   SiFlask,
   SiFastapi,
   SiAmazonaws,
   SiMicrosoftazure,
   SiGooglecloud,
   SiRedis,
   SiFirebase,
   SiPostgresql,
   SiPlotly,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
   return (
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
         <Col xs={4} md={2} className="tech-icons">
            <DiPython />
            <div className="text">Python</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiOpencv />
            <div className="text">OpenCV</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiTensorflow />
            <div className="text">TensorFlow</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiKeras />
            <div className="text">Keras</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiPytorch />
            <div className="text">PyTorch</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiScikitlearn />
            <div className="text">Scikit Learn</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiPandas />
            <div className="text">Pandas</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiNumpy />
            <div className="text">Numpy</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiJupyter />
            <div className="text">Jupyter</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiPlotly />
            <div className="text">Plotly</div>
         </Col>

         <Col xs={4} md={2} className="tech-icons">
            <SiDocker />
            <div className="text">Docker</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiFlask />
            <div className="text">Flask</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiFastapi />
            <div className="text">FastAPI</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiAmazonaws />
            <div className="text">AWS</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiMicrosoftazure />
            <div className="text">Azure</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiGooglecloud />
            <div className="text">Google Cloud</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <DiHtml5 />
            <div className="text">HTML</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <DiCss3 />
            <div className="text">CSS</div>
         </Col>

         <Col xs={4} md={2} className="tech-icons">
            <DiJavascript1 />
            <div className="text">JavaScript</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <TbBrandGolang />
            <div className="text">Golang</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <DiMongodb />
            <div className="text">MongoDB</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <DiGit />
            <div className="text">Git</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiFirebase />
            <div className="text">Firebase</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiRedis />
            <div className="text">Redis</div>
         </Col>
         <Col xs={4} md={2} className="tech-icons">
            <SiPostgresql />
            <div className="text">PostgreSQL</div>
         </Col>
      </Row>
   );
}

export default Techstack;
