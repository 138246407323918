import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Mercurial"
              description="Developed an e-commerce product recommendation system using advanced machine learning models to provide an personalized recommendations, using Python, Flask, TensorFlow, ReactJS, MongoDB, Tailwind CSS, Redux"
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="CineMatch"
              description="Developed a recommendation system designed to predict movie
ratings and offer recommendations to users based on their preferences. The system employs collaborative filtering and content-based filtering algorithms to enhance user experience Using Python, TensorFlow, Keras, Pandas, Flask."
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Sentix"
              description="Engineered Sentix, a sentiment analysis system utilizing natural language processing (NLP) techniques to analyze and classify sentiments expressed in
textual data. Sentix provides valuable insights for businesses to understand customer feedback and sentiment trends using Python, scikit-learn, Natural Language Processing (NLP), Flask"
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="MANTRA"
              description="MANTRA addresses these challenges by leveraging ML for disease prediction, computer vision for medication adherence, andDALYs analysis providing a comprehensive solution to enhance patient outcomes and healthcare efficiency, using Python, Flask, TensorFlow, Keras, OpenCV, Scikit-learn, Pandas, NumPy, ReactJS, MongoDB, Docker, Git, GitHub, Jupyter Notebook."
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="MANAS"
              description="Spearheaded MANAS, a pioneering initiative for mental analysis of nations and societies. Using advanced data analytics and predictive modeling techniques, MANAS accurately forecasts Disability-Adjusted Life Years (DALYs), providing invaluable insights for public health interventions and policy formulation, using Python, Flask, Numpy, Pandas, Scikit-learn, Matplotlib, TensorFlow, React.js"
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Akashic"
              description="Architected Akashic, an ingenious Smart Facial Login system designed for seamless and secure authentication. Akashic minimizes reliance on external API calls through custom logic and meticulous design, ensuring optimal performance and user experience while prioritizing data privacy and security using ReactJS, MongoDB, Python, TensorFlow, Flask, Tailwind CSS, OpenCV"
              ghLink="https://github.com/AnishSingh5665"
              demoLink="https://github.com/AnishSingh5665"     
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
