import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FiPlus, FiMinus, FiX } from "react-icons/fi";
import { supabase } from "../../utils/supabase";
import { PAYMENT_API, PAYMENT_API_KEY } from "../../key";

export default function CoffeeForm() {
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [message, setMessage] = useState("");
   const [coffee, setCoffee] = useState(1);
   const [loading, setLoading] = useState(false);

   const isButtonDisabled = !name || !email;
   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!name.trim()) {
         return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
         return;
      }
      if (message.length > 5000) {
         return;
      }

      setLoading(true);

      const data = { amount: coffee, receipt: `1` };
      let result;
      try {
         const response = await fetch(PAYMENT_API, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
         });
         if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
         }
         result = await response.json();
      } catch (error) {
         console.error("Error:", error);
         setLoading(false);
         return;
      }

      const { error } = await supabase.from("coffee_messages").insert([
         {
            full_name: name,
            email,
            message: message ?? null,
            number_of_coffees: coffee,
            order_id: result.id,
         },
      ]);
      setLoading(false);
      if (error) {
         return;
      } else {
         setName("");
         setEmail("");
         setMessage("");

         const script = document.createElement("script");
         script.src = "https://checkout.razorpay.com/v1/checkout.js";
         script.async = true;

         script.onload = () => {
            var options = {
               key: PAYMENT_API_KEY,
               name: "Anish Singh",
               currency: result.currency,
               amount: result.amount,
               order_id: result.id,
               description: "Buy coffee for Anish Singh.",
               handler: function (response) {
                  setTimeout(() => {
                     window.location.reload();
                  }, 1000);
               },
            };
            //@ts-ignore
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
         };

         script.onerror = () => {
            console.error("Failed to load the Razorpay script.");
         };

         document.body.appendChild(script);
      }
   };

   return (
      <>
         <Form onSubmit={handleSubmit} className="coffee-form">
            <div className="coffee-selector">
               <span>☕</span>
               <FiX fontSize={25} color="#fff" />
               <div>
                  <ButtonGroup className="" aria-label="Basic example">
                     <Button
                        variant="primary"
                        onClick={() => setCoffee((c) => Math.max(1, c - 1))}
                     >
                        <FiMinus />
                     </Button>
                     <Button variant="primary">{coffee} coffee</Button>
                     <Button
                        variant="primary"
                        onClick={() => setCoffee((c) => c + 1)}
                     >
                        <FiPlus />
                     </Button>
                  </ButtonGroup>
               </div>
            </div>

            <Form.Group controlId="exampleForm.ControlInput1">
               <Form.Label>Name</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Yourname"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
               />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
               <Form.Label>Email address</Form.Label>
               <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
               />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
               <Form.Label>Say something nice</Form.Label>
               <Form.Control
                  as="textarea"
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Write here..."
               />
            </Form.Group>
            <div className="d-grid mt-4">
               <Button
                  type="submit"
                  variant="primary"
                  disabled={loading || isButtonDisabled}
               >
                  {loading ? "Sending..." : "Buy " + coffee + " Coffee"}
               </Button>
            </div>
         </Form>
      </>
   );
}
