import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
   BsGithub,
   BsTwitter,
   BsLinkedin,
   BsInstagram,
   BsGeoAltFill,
   BsEnvelopeFill,
} from "react-icons/bs";
import { supabase } from "../../utils/supabase";
export default function ContactForm() {
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [message, setMessage] = useState("");
   const [show, setShow] = useState(false);
   const [loading, setLoading] = useState(false);
   const isButtonDisabled = !name || !email || !message;
   const handleClose = () => setShow(false);
   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!name.trim()) {
         return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
         return;
      }
      if (message.length > 5000) {
         return;
      }
      setLoading(true);
      const { error } = await supabase
         .from("contact_messages")
         .insert([{ full_name: name, email, message }]);

      if (error) {
         console.error("Error inserting data:", error);
         return;
      } else {
         setShow(true);
         setLoading(false);
         setName("");
         setEmail("");
         setMessage("");
      }
   };

   return (
      <>
         <Modal centered show={show} onHide={handleClose} className="modal">
            <Modal.Header closeButton>
               <Modal.Title>Hello, Anish here!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               Thanks for contacting me. I will contact you soon!
            </Modal.Body>
         </Modal>

         <ul className="footer-icons">
            <li className="social-icons">
               <a
                  href="https://github.com/AnishSingh5665"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <BsGithub fontSize={25} />
               </a>
            </li>
            <li className="social-icons">
               <a
                  href="https://twitter.com/Anish_Singh_32"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <BsTwitter fontSize={25} />
               </a>
            </li>
            <li className="social-icons">
               <a
                  href="https://www.linkedin.com/in/anishsinghssaa/"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <BsLinkedin fontSize={25} />
               </a>
            </li>
            <li className="social-icons">
               <a
                  href="https://www.instagram.com/wht_hats_ani56"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <BsInstagram fontSize={25} />
               </a>
            </li>
         </ul>

         <div className="contact-details">
            <div>
               <BsEnvelopeFill />
               <a href="mailto:29991csaiml@gmail.com">29991csaiml@gmail.com</a>
            </div>
            <div>
               <BsGeoAltFill />
               <span>Mangla Vihar 2, Kanpur, India</span>
            </div>
         </div>

         <Form onSubmit={handleSubmit} className="coffee-form">
            <Form.Group controlId="exampleForm.ControlInput1">
               <Form.Label>Name</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Yourname"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
               />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
               <Form.Label>Email address</Form.Label>
               <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
               />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
               <Form.Label>Message</Form.Label>
               <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Write message here..."
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  maxLength={5000}
               />
            </Form.Group>
            <div className="d-grid mt-4">
               <Button
                  type="submit"
                  variant="primary"
                  disabled={loading || isButtonDisabled}
               >
                  {loading ? "Sending..." : "Send"}
               </Button>
            </div>
         </Form>
      </>
   );
}
